<template>
  <div class="liff-instant-survey-page">
    <div class="page__toolbar">
      <!-- <div class="toolbar-back-action" @click="backToInstantSurveyListPage">
        <a-button type="link">
          <template #icon>
            <LeftOutlined :style="{ color: 'white', fontWeight: 'bold' }" />
          </template>
        </a-button>
      </div> -->
      <div class="toolbar-title">{{ instantSurveyData.name }}</div>
    </div>

    <div class="page__body">
      <div class="body__wrapper">
        <!-- 簽到內容 -->
        <div class="instant-survey-content">
          <div
            class="letwecare-survey-card"
            v-for="survey in instantSurveyData.contents"
          >
            <div class="card-title">{{ survey.name }}</div>
            <div class="card-body" align="center">
              <template v-if="actionState == 'edit'">
                <template v-if="survey.type == 'number'">
                  <a-input-number
                    v-model:value="survey.value"
                    class="body-number-input"
                    size="large"
                    :min="1"
                  />
                </template>

                <template v-if="survey.type == 'text-field'">
                  <a-input
                    v-model:value="survey.value"
                    class="body-input"
                    size="large"
                  />
                </template>

                <template v-if="survey.type == 'radio'">
                  <a-radio-group v-model:value="survey.value">
                    <a-radio  
                      v-for="opt in survey.selection_items"
                      :value="opt.label"
                      :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem' }"
                    >{{ opt.label }}</a-radio>
                  </a-radio-group>
                </template>

                <template v-if="survey.type == 'checkbox'">
                  <a-checkbox-group v-model:value="survey.value">
                    <a-checkbox  
                      v-for="opt in survey.selection_items"
                      :value="opt.label"
                      :style="{ display: 'flex', height: '40px', lineHeight: '40px', fontSize: '1.3rem', marginLeft: 0 }"
                    >{{ opt.label }}</a-checkbox>
                  </a-checkbox-group>
                </template>

                <template v-if="survey.type == 'slider'">
                  <span :style="{ fontSize: '1.8rem', fontWeight: '500' }">{{ survey.value }}</span>
                  <a-slider
                    v-model:value="survey.value"
                    :min="survey.rule.min"
                    :max="survey.rule.max"
                  />
                </template>
              </template>

              <template v-if="actionState == 'lock'">
                <template v-if="Array.isArray(survey.value)">
                  <div class="body-value-text text--small" v-for="text in survey.value">{{ text }}</div>
                </template>

                <template v-else>
                  <span class="body-value-text" :class="{ 'text--small': survey.value.length>6 }">{{ survey.value }}</span>
                </template>
              </template>
            </div>
          </div>
        </div>
        <!-- 簽到內容 -->
      </div>
    </div>

    <div class="page__actions">
      <template v-if="instantSurveyData.is_open">
        <template v-if="actionState=='edit' && !Object.keys(userReplyData).length">
          <div class="action-btn" @click="submit">送出</div>
        </template>
        
        <template v-if="actionState=='lock' && Object.keys(userReplyData).length">
          <div class="action-btn btn--white" @click="actionState='edit'">修改紀錄</div>
        </template>

        <template v-if="actionState=='edit' && Object.keys(userReplyData).length">
          <div class="action-btn btn--white" :style="{ width: '50%' }" @click="actionState='lock'">取消</div>
          <div class="action-btn" :style="{ width: '50%' }" @click="updateRollcallContents">更新紀錄</div>
        </template>
      </template>
      
      <template v-else>
        <div class="action-btn btn--white">尚未開放回覆</div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import { message } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import liff from '@line/liff/dist/lib'
import api from '@/apis'
import DateUtil from '@/utils/DateUtil'

export default defineComponent({
  components: {
    LeftOutlined
  },
  setup() {
    const route = useRoute();

    /* Query */
    // console.log(route.query['liff.state']);
    // const courseId = route.query

    /* Liff */
    const context = ref({});
    const initLiff = (async () => {
      try {
        await liff.init({ liffId: process.env.VUE_APP_LIFF_INSTANT_SURVEY });
        context.value = liff.getContext();
        console.log(context.value);
      } catch (err) {
        console.log(err.code, err.message);
      }
    });

    /* Data */
    const userId = ref('');
    const instantSurveyData = ref({});
    const userReplyData = ref({});

    /* Action State */
    const actionState = ref('edit'); // 編輯狀態 (edit|lock)

    /* Api */
    const getUserId = (async () => {
      let line_id;
      if (process.env.VUE_APP_ENV == 'production') {
        line_id = context.value.userId;
      } else if (process.env.VUE_APP_ENV == 'development') {
        line_id = process.env.VUE_APP_DEV_LINE_ID;
      }

      const response = await api.v1.line.parserLineId({
        line_id: line_id
      });
      userId.value = response.data.result.uid;
    });

    const getInstantSurveyData = (async () => {
      const response = await api.v1.course.findOneInstantSurvey(route.params.course_id, route.params.id);
      instantSurveyData.value = response.data.result;
      
      // 加入 value 欄位
      instantSurveyData.value.contents.forEach(item => {
        if (item.type == 'slider') {
          item.value = item.rule.min;
        }
        else {
          item.value = '';
        }
      });

      console.log(instantSurveyData.value)
    });

    const getUserReplyData = (async () => {
      const response = await api.v1.user.getInstantSurveyData(userId.value, route.params.id);
      userReplyData.value = response.data.result;
      console.log(userReplyData.value)

      // 放入已回覆的資料
      instantSurveyData.value.contents.forEach(surveyContentItem => {
        userReplyData.value.contents.forEach(replyItem => {
          if (surveyContentItem.name == replyItem.name) {
            surveyContentItem.value = replyItem.value;
          }
        });
      });

      if (Object.keys(userReplyData.value).length > 0) {
        actionState.value = 'lock';
      } else {
        actionState.value = 'edit';
      }

      console.log(response.data.result);
    });

    /* Mounted */
    onMounted(async () => {
      await initLiff();
      await getUserId();
      await getInstantSurveyData();
      await getUserReplyData();
    });

    return {
      /* Data */
      userId,
      instantSurveyData,
      userReplyData,

      /* Action State */
      actionState,

      /* Api */
      getUserReplyData
    }
  },
  methods: {
    /* Router */
    backToInstantSurveyListPage() {
      this.$router.push({
        name: 'LiffCourseInstantSurveyList',
        params: {
          course_id: this.$route.params.course_id
        }
      });
    },

    /* Function */
    async submit() {
      const paramsData = {
        course_id: this.$route.params.course_id,
        contents: this.instantSurveyData.contents
      }

      try {
        await api.v1.user.replyInstantSurvey(
          this.userId,
          this.$route.params.id,
          paramsData
        );
        
        this.getUserReplyData();
        this.actionState = 'lock';
        message.success('送出成功');
      } catch (error) {
        console.log(error);
        message.error('送出發生錯誤，請稍後再試');
      }
    },
    async updateRollcallContents() {
      const paramsData = {
        contents: this.instantSurveyData.contents
      }

      try {
        await api.v1.user.updateOneInstantSurvey(
          this.userId,
          this.$route.params.id,
          paramsData
        );
        this.actionState = 'lock';
        message.success('更新成功');
      } catch (error) {
        console.log(error);
        message.error('更新發生錯誤，請稍後再試');
      }
    }
  }
})
</script>

<style lang="scss">
.liff-instant-survey-page {
  min-height: 100vh;
  background: #EAEAEA;

  .page__toolbar {
    height: 57px;
    background: #3D3D3D;
    position: relative;

    .toolbar-back-action {
      position: absolute;
      left: 12px;
      top: 12px;
    }

    .toolbar-title {
      color: white;
      text-align: center;
      font-weight: 500;
      font-size: 1.25rem;
      letter-spacing: 0.0425rem;
      line-height: 57px;
    }
  }

  .page__body {
    .body__wrapper {
      padding: 24px 16px 56px 16px;
    }
  }

  .page__actions {
    width: 100%;
    height: 52px;
    position: fixed;
    left: 0;
    bottom: 0;

    .action-btn {
      display: inline-block;
      width: 100%;
      height: 52px;
      color: white;
      background: #FF8A00;
      box-shadow: inset 0px 2px 0px rgba(0, 0, 0, 0.05);
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 52px;
      text-align: center;

      &.btn--white {
        color: #212121;
        background: white !important;
      }
    }
  }

  // body
  .lession-selector {
    margin-bottom: 20px;

    .selector-label {
      font-weight: 500;
      font-size: 1.3rem;
      letter-spacing: 0.03rem;
      margin-bottom: 4px;
    }

    .ant-select {
      width: 70%;
      background: white;
      border-radius: 0;
      font-size: 1.2rem;
      margin-bottom: 16px;

      .ant-select-selection-item {
        text-align: center;
      }
    }
  }
}
</style>